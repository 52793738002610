#surveyElement-cdla {

    .alert.alert-danger{
        span{
            color: #dc3545;
        }
    }

    .progress-bar {
        background-color: #2980B9;
    }

    .sv_prev_btn{
        background-color: #00BFFF !important;
    }

    .sv_next_btn{
        background-color: #2980B9 !important;
    }

    .sv_complete_btn{
        background-color: #EF6523 !important;
    }
}

.botao-salvar-questionario.btn-apenas-salvar{
    background-color: #2980B9!important;
    font-weight: bold;
}

#btn-salvar{
    background-color: #2980B9;
    font-weight: bold;
}

#btn-salvar-enviar{
    background-color:#EF6523;
    font-weight: bold;
}
