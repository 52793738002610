@import 'questionario-cdla.component.scss';
@import 'questionario-cemupe.component.scss';
@import 'questionario-nova.component.scss';


.titulo-enviar-questionario{
    font-size: 20px;
    color: white !important;
    padding: 30px !important;
    margin-top: 60px;
}

.text-alerta-quesitonario-enviado{
    font-size: 18px !important;
    text-transform: inherit;
}

.titulo-questionario{
    background-color: white;
    padding: 20px 10px 10px 10px;

    margin: 25px 0;
	color: #505050;
	font-weight: bold;
	text-align: center;
	font-size: 18px;
}

.div-carregando-questionario{
    text-align: center;
    top: 50%;
    position: fixed;
    width: 100%;
}

.titulo-questionario-enviado{
    text-align: center;
    margin-top: 60px;
}

.botao-salvar-questionario{
    padding: 12px;
    border: none;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    margin: 0px !important;
}

.surveyElement {
    background-color: white;
    margin: 0px 5px 5px 5px;
    position: relative;
    padding: 10px 10px 1.6rem 10px;

    .sv_q_radiogroup{
        width: 100%;
    }

    .sv_q_checkbox_label{
        width: 100%;
    }

    .sv_q_radiogroup_control_label{
        span{
            position: relative !important;
            top: 3px;
            float: right;
            clear: both;
            width: calc(100% - 40px);
        }
    }

    .sv_q_checkbox_control_label{
        span{
            position: relative !important;
            top: 3px;
            float: right;
            clear: both;
            width: calc(100% - 40px);
        }
    }

    .sv_q_radiogroup span:first-child {
        position: relative !important;
        top: 3px;
        float: right;
        clear: both;
        width: calc(100% - 40px);
    }

    .sv_q_checkbox span:first-child {
        position: relative !important;
        top: 3px;
        float: right;
        clear: both;
        width: calc(100% - 40px);
    }

    .progress-bar {
        padding: 5px 5px 5px 10px;
        float: left;
        margin: 0px !important;
    }
    
    .sv_page_title span{
        font-weight: bold !important;
        font-size: 22px !important;
        text-decoration: underline;
    }

    p, span, a{
        font-weight: bold;
        color: #343a40;
    }
    
    .sv_progress {
        display: none;
    }

    .sv_progress span{
        color: white !important;
        padding: 10px;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 15px;
        margin-bottom: 1rem;
        font-weight: bold;
        color: #343a40;
        font-size: 18px;
    }

    .sv_bootstrap_css .sv_qstn {
        padding: 5px;
    }

    .sv_q_imgsel_label{
        padding: 0px !important;
    }

    input[type="button"]{
        padding: 12px;
        border: none;
        width: 100%;
        margin: 0 auto;
        color: white;
        text-transform: uppercase;
        position: relative;
        margin-top: 20px;
    }

    input[type="text"]{
        color: #343a40 !important;
    }

    input[type="number"]{
        color: #343a40 !important;
    }

    .checkbox {
        margin: 0px;
    }

    tr {
        padding-bottom: 15px;
    }

    td {
        padding: 15px 20px 0px 0px;
        margin: 5px 5px 5px 0px;
        font-weight: bold;
        color: #343a40;
    }

    label{
        width: 100% !important;
        padding: 0px 10px 0px 0px;
    }

    td input{
        margin-top: -40px;
    }

    .panel-footer.card-footer{
        margin-top: 20px;
    }

    input[type="checkbox"] {
        height: 25px;
        width: 25px;
        margin-right: 2px;
    }

    input[type="radio"]{
        height: 25px;
        width: 25px;
        margin-right: 7px;
    }

    input[type="file"]{
        height: 25px;
        width: 100%;
        padding: 0px;
        background-color: transparent;
        border: none;
        color: transparent;
    }

    .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.sv_q_matrix td::after {
        padding-left: 35px;
    }

    img {
        margin: auto !important;
    }

    .sv_q_imgsel.sv_q_imagepicker_inline div{
        border: none;
    }

    textarea{
        height: 100px;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        background-color: transparent;
        border-color: #343a40;
        border: 0.5px solid;
    }

    select{
        height: 40px;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        background-color: transparent;
        border-color: #343a40;
        border: 0.5px solid;
    }

    .panel-footer.card-footer{
        text-align: center;
    }

    .sv_body.sv_completed_page{
        text-align: center !important;
    }

    .sv_q_rating label.sv_q_rating_item.active{
        opacity: 0.5;
    }
    
    .sv_q.sv_qstn{
        clear: both;
        overflow: hidden;
    }
    
    .sv_q_rating{
        padding-top: 10px;
    }

    .sv_q_imgsel.sv_q_imagepicker_inline{
        width: auto;
        display: inline-block;
    }

    label.btn.btn-default.btn-secondary.active{
        background-color: #343a40;
        padding: 10px 20px 10px 20px;
        margin: 0px 3px 0px 0px;
        color: white;
        opacity: 0.5;
        display: inline-block;
    }
    
    label.btn.btn-default.btn-secondary{
        background-color: #343a40;
        padding: 10px 20px 10px 20px;
        margin: 0px 5px 5px 0px;
        color: white;
        display: inline-block;
    }

    .sv_q_rating label.sv_q_rating_item{
        color: white;
        background-color: #007bff;
        padding: 0px 5px 0px 5px;
        margin: 5px;
    }

    
    input[type="button"]{
        padding: 12px;
        border: none;
        width: 100%;
        margin: 0 auto;
        color: white;
        text-transform: uppercase;
        position: relative;
        margin-top: 80px;
    }

    .sv_progress span{
        color: white !important;
        padding: 10px;
    }

    p, span, a{
        font-weight: bold;
        color: #343a40;
    }
    
    h1, h2, h3, h4, h5, h6 {
        margin-top: 15px;
        margin-bottom: 1rem;
        font-weight: bold;
        color: #343a40;
        font-size: 15px;
    }

    .sv_bootstrap_css .sv_qstn {
        padding: 5px;
    }

    .sv_q_imgsel_label{
        padding: 0px !important;
    }
    
    input[type="text"], input[type="email"], input[type="color"],
    input[type="password"], input[type="tel"], input[type="number"], 
    input[type="date"], input[type="datetime-local"],
    input[type="image"], input[type="month"], input[type="range"], 
    input[type="search"], input[type="time"], input[type="url"], input[type="week"]  {
        height: 40px;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        background-color: transparent;
        border-color: #343a40;
        border: 0.5px solid;
    }
    
    .sv_q_imgsel.sv_q_imagepicker_inline.checked{
        border: 2px solid #343a40;
    }
}

.titulo-pagina-nao-encontrada{
    color: #343a40;
    font-weight: bold !important;
    font-size: 25px;
}

.subtitulo-pagina-nao-encontrada{
    color: #343a40;
    font-size: 20px;
}

.img-not-found{
    width: 80px;
}

.bg-page-not-found{
    height: 100%;
    background-color: #F5F5F5;
}
