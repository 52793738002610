// regular style toast 
@import '~ngx-toastr/toastr';
@import "~bootstrap/dist/css/bootstrap.css";
@import "~ngx-smart-modal/ngx-smart-modal";

@import 'app/pages/questionario/questionario.component.scss';

.pointer{
    cursor: pointer;
}

.bold{
    font-weight: bold !important;
}

.text-center{
    text-align: center;
}

h1, h2, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Montserrat", sans-serif !important;
    color: #343a40  ;
}

h2 {
    font-size: 25px;
}

h4, h3, h2, h1, .table-header {
    font-family: "Montserrat", sans-serif !important;
    text-transform: uppercase;
    font-weight: normal ;
    color: #343a40  ;
}

html, body {
    height: 100%;
    /*overflow-x: hidden;*/
    font-family: "Montserrat", sans-serif !important;
    font-size: 12px;
    color: #343a40;
    -webkit-overflow-scrolling: none;
}

html{
    min-height: 100%;
    width: 100%;
    min-width: 100%;
  }
  
  body{
    margin: 0px;
    padding: 0px;
    height: 100vh;
   }

.header{
    color: white;
    padding: 10px;
    font-size: 12px;

    img{
        width: 250px;
        padding: 10px 0;
    }
}

.overflow-hidden{
    overflow: hidden;
}

.float-right{
    float: right;
}

@for $i from 0 through 150 {
    .margin-top-#{$i}{
      margin-top: 0px + $i !important
    }    
  
    .invert-margin-top-#{$i}{
      margin-top: (0px + $i)*-1 !important
    }  
  }    
  
  @for $i from 0 through 150 {
      .margin-bottom-#{$i}{
        margin-bottom: 0px + $i !important
      }    
  
      .invert-margin-bottom-#{$i}{
        margin-bottom: (0px + $i)*-1 !important
      }  
  } 
  
  @for $i from 0 through 150 {
      .padding-top-#{$i}{
          padding-top: 0px + $i !important
      } 
    }    
    
    @for $i from 0 through 150 {
        .padding-bottom-#{$i}{
          padding-bottom: 0px + $i !important
        }    
    } 
  
    @for $i from 0 through 150 {
      .padding-left-#{$i}{
        padding-left: 0px + $i !important
      }    
  } 
  
  
  @for $i from 0 through 150 {
      .padding-right-#{$i}{
        padding-right: 0px + $i !important
      }    
  }

.titulo-modal{
    color: #343a40;
    font-weight: bold;
    font-size: 25px;
}

.subtitulo-modal-destaque{
    color: black;
    font-weight: bold;
    font-size: 17px;
}

.subtitulo-modal{
    color: #343a40;
    font-size: 15px;
}

.btn-default{
    border-radius: 0px  !important;
    border: none  !important;
    color: white;
    padding: 8px;
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .div-btn-left-modal{
        margin-bottom: 20px;
    }
}

.right{
    float: right;
}

.left{
    float: left;
}

.margin-auto{
    margin: 0 auto;
}

.conteudo{
    padding-bottom: 60px !important;
}

.font14{
    font-size: 14px !important;
}

.font16{
    font-size: 16px !important;
}

.magin0auto{
    margin: 0 auto;
}

.icone-preparo-exame{
    width: 80px;
}